// HPRS stuff



.head_sub {
   font-size: 120%;
   .head_type {
//     font-size: 125%; 
     margin-bottom: $small-pad;
   }
   .arid { text-align: right; }
}

.hprs_content {

   ul {
      width: 100% !important;
   }


}

div.book_header {
   margin-bottom: $medium-pad;
   padding: $mini-pad $medium-pad $mini-pad $medium-pad;
   background-color: $superlight-grey;
   color: $dark-grey;
//   @include rounded();
}


div#timeline {
   margin-top: $medium-pad;
}

// booking dialogs
.user_line {
    .arrival {
      .date {
         font-weight: bold;
      }
   }

    .costs {
      .price {
         font-weight: bold;
      }
   }
}

span.tooltip_quest {
}


.occupation-list {

   h4 {
      margin-bottom: $large-pad;
   }

   .occupation-list-pane {
      margin-bottom: $medium-pad;
      font-size: 75%;
      .date {
         padding-top: $small-pad;
         background-color: $light-grey;
       }
    }
    .buttons { 
       margin-top: $medium-pad; 
       margin-bottom: $small-pad; 
    }      


    //         .bewartet { background-color: lighten($bg_status_bewartet, 25%) !important; }
    //         .Winter { background-color: $bg_status_winter !important; }
    //         .geschlossen { background-color: $bg_free_closed !important; color: #ffffff; }
}


#accordion {
   .card {
     border: 0;
     border-radius: 0;
   }
}


// donut

.donut_con {
//   min-height: 540px;
//   min-width: 540px;
}

.donut_line {
  min-height: 140px;
//  min-width: 240px;
}

.daybox {
 //  min-width: 91px;
 //  min-height: 139px;
}

.daybox_donut {
   min-width: 75px;
   min-height: 75px;
}

.cdc-container {
 // min-height: 75px;
 // min-width: 75px;

  .dn_sr,
  .dn_sum {
     font-weight: bold;
     font-size: 85%;
  }

  .dn_dl {
    font-size: 100%;
  }

  .dn_sr {
     // font-weight: bold;
   }

}

.donut_paginator {
  width: 100% !important;
  margin-top: $medium-pad;
  margin-bottom: $small-pad;
  ul.pagination {
     width: auto !important;
//   min-height: 38px;
  }
}

.donutlegend {

   span {
      min-width: 30px !important;
      min-height: 30px !important;
   }
}


.agb_box {
   margin-bottom: $medium_pad;
   #agb_content {
     position: relative;
     overflow: hidden;
     width: 100%;
     height: 100%;
     border: 1px &grey solid;
   }
}
