/* CSS for ZMS elements only */

div.news_box {
   width: 100%;
   padding: $medium-pad;
   background-color: $yellow !important;
   .title { font-weight: bold; }
   .more {}
}

.ZMSGraphic {

    .graphic {
	max-width: 100% !important;
        img {
           max-width: 100% !important;
        }
    }

    &.floatleft {
        float: left;
        padding-right: $medium-pad !important;
        margin: 0 $large-pad $medium-pad 0;
    }

    &.floatright {
        float: right;
        padding-left: $medium-pad !important;
    }

    &.left .graphic {
        text-align: left !important;
        width: 100% !important;
        position: relative;
    }

    &.right .graphic {
        text-align: right !important;
        width: 100% !important;
        position: relative;
    }

    &.center .graphic {
        text-align: center !important;
        width: 100% !important;
        position: relative;
    }
}

.image {
    position: relative;
}

body div.ZMSGallery {
    width: 100%;
    display: block;
    ul {
        list-style: none;
        overflow: hidden;
        li {
            display: block;
            width: 240px;
            float: left;
            margin: 0 15px 15px 0;
        }
        li:nth-child(3n+0) {
            clear: right;
        }

    }
}

body.ZMSSearch {
    div.row {
        margin-left: 0;
        margin-right: 0;
    }
    hr.clear {
        visibility: hidden;
    }
    div.full-width {
        float: right;
        clear: right;
    }
    form#searchForm0 {
        div.searchInputBox {
            label {
                float: left;
                margin-right: 1em;
                padding: 8px 13px;
            }
            input {
                width: 25%;
                float: left;
            }
        }
        div.searchButtonBox input {
            width: 5em;
            background-color: $orange;
            color: #fff;
            float: left;
            &:hover {
                background-color: darken($orange, 10%);
            }
        }
    }
    ol.searchResults h3 {
        font-size: 16px;
    }
}
