.center-vertical {
  display: flex;
  align-items: center;
}

.center-horizontal {
  display: flex;
  justify-content: bottom;
}

.right-horizontal {
//  display: flex;
  text-align: right;
  float: right !important;
  justify-content: right;
}

.rounded{
   @include rounded();
}

.no-padding{
  padding: 0 !important;
}

.border-bottom{
  position: relative;
  display: inline-block;
  &:after{
    bottom: -5px;
    display: block;
    content:'';
    position:absolute;
    width:100%;
    height:1px;
    background: -webkit-gradient(linear, 0 0, 100% 0,color-stop(0, grey), color-stop(1, transparent));
  }
}



div.empty { background-color: transparent !important; }

.clicker:hover {
	background-color: #f4f4f4;
}

/** nice stuff **/
.shadow_mini {
   @include box-shadow(0, 1px, 4px, rgba(41,51,57,.5))
}

.shadow_text {
   text-shadow: 0 1px 1px rgba(0,0,0,.5);
}

.just_border {
   border: 1px solid $medium-grey;
}

.greybox {
   padding: 1em;
   background-color: #eeeeee; 
   border-color: #444444; 
}


.svgshadow {
   filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3, Color='#444')";
   filter: url(#drop-shadow);
   /* Use -webkit- only if supporting: Chrome < 54, iOS < 9.3, Android < 4.4.4 */
   -webkit-filter: drop-shadow( 3px 3px 3px rgba(0,0,0,0.4) ); 
   filter: drop-shadow( 3px 3px 3px rgba(0,0,0,0.4) );
}
