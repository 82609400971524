// Footer
footer {
   margin-top: rem(50);
   .jumbotron {
      color: $light-grey;
      background-color: $green_grey;
      @extend .shadow_mini;
      @extend .shadow_text;
      a,
      a:link {
         color: $light-grey;
      }

      .finance {
         font-size: rem(14);
      }
      .social_nav {
      }
   }
   .col_help_nav {
//      @extend .right-horizontal;
   }   

   .info { 
      .row { 
         margin-right: 0; 
         margin-left: 0;
      }
    }

   .copyright {
      text-align: center;
      color: $grey;
      a,
      a:link {
         color: $grey;
      }
   }
}
