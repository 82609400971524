/* new form styles */
::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input:not([type="submit"],[type="radio"],[type="checkbox"],[type="file"],[class="no-form-control"],[class="form-control"],[class="custom-control-input"]),
select:not([class="no-form-control"],[class="ui-datepicker*"]),
textarea:not([class="no-form-control"]) {
    @extend .form-control;
}

//select.date {
//   @extend .form-control;
//}

form .nowrap,
input[type="radio"],
select.date {
   display: inline !important;
   width: auto !important;
}

//select.date {
//   margin-right: 0.5em;
//   margin-left: 0.5em;
//}

input {
   &[type="radio"],
   &[type="checkbox"] {
      display: inline;
      @include scaleit(1.5);
      padding: rem(10);
      margin-top: $small-pad;
      margin-left: $medium-pad;
      @include media-breakpoint-up(lg) {
         width: auto;
      }
   }
   &[type="radio"] {
      @include media-breakpoint-down(lg) {
         margin-left: $small-pad;
      }
   }
}

input[type="submit"],
input.btn,
input.btn_reset {
//    background-color: #686868;
//    border: medium none;
//    color: white;
//    cursor: pointer;
//    font-size: 13px;
//    padding: 4px;
//    margin: 4px;
//    border-radius: 3px;
}



div.login_form {
   width: 100%;
   display: inline-block;
   margin-bottom: $medium-pad;
   margin-top: $medium-pad;
}



.ZMSForm {
   width: 100%;
   fieldset {
      background-color: $ar_green_ultralight;
      padding-bottom: $medium-pad;
      @include rounded();
      @extend .shadow_mini;

      margin-bottom: $large-pad;

      legend {
         width: 33%;
         color: $white;
         font-size: $medium-pad;
         padding: $mini-pad;
         padding-left: $small-pad;
         margin-left: $medium-pad;
         background-color: $green;
         @include rounded();
         @extend .shadow_mini;


         @include media-breakpoint-down(md) {
           width: 45%;
         }
         @include media-breakpoint-down(sm) {
           width: 65%;
         }
         @include media-breakpoint-down(xs) {
           width: 85%;
         }
      }


      div.field {
         width: 100%;
         float: left;
         padding-top: $small-pad;

         //border-bottom: 1px $black dotted;

         &.haserror {
            border: 1px $red dotted;
            background-color: lighten($red,50%) !important;
         }

         div.required {
            label { color: $red; }
         }
         div.optional { }

         div.labelbox {
            width: 30%;
            padding: $small-pad;
            display: block;
            float: left;


            // breakpoint down
            @include media-breakpoint-down(md) {
               float: none;
               clear: both;
               width: 100%;
            }



            label {
               font-weight: bold;

               div.description {
                  font-size: 75%;
               }

               div.description {
                  font-size: 75%;
               }
            }
         }
         div.inputbox {
            width: 60%;
            float: left;
            display: block;

            > div {
               margin-left: $small-pad;
            }


// niels test
@include media-breakpoint-down(md) {
   float: none;
   clear: both;
   width: 100%;
}

         }

         input,
         textarea,
         select {
//            border: 1px solid $grey;
//            background-color: $white !important;
         }
         input:focus,
         textarea:focus {
            background-color: $ar_form_active_bg;
         }
      }
      .odd { background-color: $odd; }
      .even {  }
      .clearfix {}
   }
   p.description {
      font-weight: bold;
   }
}


// login (modal)

.loginmodal-container {
   padding: $large-pad;
   max-width: 375px;
   width: 100% !important;
   background-color: $white;
   margin: 0 auto;
   overflow: hidden;
//   font-family: Roboto;

   @include rounded();
   @extend .shadow_mini;

   .form-login {
      text-align: center;
      .input-group-addon { 
          width: rem(52); 
      }
      button {
         margin-bottom: $medium-pad;
      }
   }
}

.loginmodal-container h1 {
  text-align: center;
  font-size: rem(24);
//  font-family: Roboto;
}

.login-help{
   margin-top: $large-pad;
//  font-size: rem(12);
}


input.redborder { border: 1px #cc0000 dotted !important; background-color: #d1b1b1 !important; }

.searchform {
    margin-bottom: $medium-pad;
}



// page button panel
.page_btns {
   width: 100%;
   padding-top: $small-pad;
   padding-bottom: $small-pad;

   button,
   a {
//      margin-left: rem(1);

      @include media-breakpoint-down(md) {
         width: 100%;
      }
   }
}

.book_btns_container {
   width: 100%;
   text-align: right;
}


div.name {
   .fullname,
   .pre,
   .post {
      font-weight: bold;
   }
}

// booking steps

.user_line,
.form_line {
   margin-bottom: 1em;
   padding-top: .5em;
   padding-bottom: 1em;
   background-color: $light-grey;
   .single { 
      margin-top: 2em;
//      height: 4em; 
   }
   .post_line {
//      color: #7777bb;
      @include opacity(0.75);
   }
   span.delete {
//      padding-top: 2em;
   }
   @include media-breakpoint-down(sm) {
      label { margin-top: .5em; font-weight: bold; }
      div.fullname { 
         label { display: none !important; } 
      }
   }
}



.user_inputs {
    background-color: $superlight-grey;
//    padding: $small-pad;

}



div.user_input {

//   input .isNewReq {
//       border: 1px solid $red !important;
//       background-color: #FFB1B1;
//   }

//   input .isNewOpt {
//       border: 1px solid $grey !important;
//       background-color: #FFB1B1;
//   }

//   input .isok {
//       border: 1px solid $green !important;
//       background-color: #FFB1B1;
//   }

}
