$ease-break: cubic-bezier(0.07, 0.54, 0, 0.99);

$hard-site-url-prefix:	"http://work2.alpinres.org";

// niels ( for glyph)

$bootstrap-sass-asset-helper: false;
$icon-font-name: 'glyphicons-halflings-regular';
$icon-font-svg-id: 'glyphicons_halflingsregular';
$icon-font-path: '../fonts/';
$mdi-font-path: $icon-font-path;


//Vars
$assetPath: "/theme_alpinres/style/images/build";
$assetStaticPath: "/theme_alpinres/style/images/static/";

$payways-list: VIS ECA PFC PSC PAP TWI;
//$payways-width-list: 80px 60px 100px 115px 90px 75px;
$payways-width-list: 100px 80px 120px 135px 110px 95px;
$enable-shadows:	true;

$white:           #FBFBFB;
$superlight-grey: #EEEEEE;
$light-grey:      #E6E6E6;
$medium-grey:     #DADADA;
$grey:            #3c3c3b;
$dark-grey:	  #888889;
$black:           rgb(26,26,24);

$bg_grey:	  #ebeced;

$blue_grey:	  #37454d;
$green_grey:      #5c7d5f;
$green_full:	  #00a000;

$orange:          #FF9900;
$green:           #5c7d5f;
$red:             #e03b0f;
$blue:		  #1111ff;
$yellow:	  #f8f3b4;

$bg_status_important:   lighten($red,20%);

// new niels colors

$ar_form_active_bg:	#fcfbde;

// AR green
$ar_green:		#018001;
$ar_green_dark:		#005400;
$ar_green_light:	#a4dea9;
$ar_green_ultralight:   #e9fce9;
$ar_active_button_green: #537456;

// AR blue
$ar_blue:		#0000fd;
$ar_blue_dark:		#000070;
$ar_blue_light:		#5656ff;
	
$odd:			#ddddff;
$even:			transparent;

$ar_erteilt_bg:		mix($white,$ar_green,90%);
$ar_erfuellt:		mix($superlight-grey,$ar_green_ultralight,85%);
$ar_storniert:		mix($superlight-grey,$red,85%);

$ar_group_leader:	mix($white,$red,90%);

// ratings
$rt_summer:		#A3803B;
$rt_winter:		#5577B7;

// hprs status

$bg_status_winter:      #83caff;
$bg_status_winter_selected:     darken($bg_status_winter,40%);
$bg_status_winter_input:        #ccefff;

$bg_status_closed:      #444444;

$bg_free:		#aecf00;
$bg_free_lower_20:	#f7f605;
$bg_free_lower_0:	#ca4242;
$bg_free_closed:	$bg_status_closed;
$bg_status_bewartet:	#ffb515;
$bg_status_bewartet_selected:	darken($bg_status_bewartet,40%);

$bg_maps:		$medium-grey;
$bg_status_unbekannt:	$grey;


$mini-pad: 0.25rem;
$small-pad: 0.5rem;
$medium-pad: 1rem;
$large-pad: 2rem;

$small-factor: 1;
$medium-factor: 2;
$large-factor : 3;

$mq-responsive: true;

$mq-breakpoints: (
        mobile:  320px,
        tablet:  740px,
        desktop: 980px,
        wide:    1300px,

  // Tweakpoints
        desktopAd: 810px,
        mobileLandscape: 480px
);

$mq-static-breakpoint: desktop;


// breacrumbs
$breadcrumb-divider:	">";


// tables
$table-cell-padding:            $small-pad !default;
$table-sm-cell-padding:         $mini-pad !default;
$table-bg:                      transparent !default;
$table-inverse-bg:              $green !default;
//$table-inverse-color:           $body-bg !default;


// bs4
$dropdown-link-active-color:     $ar_green_light;
$dropdown-link-active-color:     $black;
