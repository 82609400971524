.bewartet {
   color: $black;
   a,
   a:link,
   a:visited {
      color: $black;
   }

   background-color: $bg_status_bewartet !important;
   &.light {
      background-color: lighten($bg_status_bewartet,35%) !important; 
   }
}

.Winter,
.winter {
   color: $black;
   background-color: $bg_status_winter !important;
   a,
   a:link,
   a:visited {
      color: $black;
   }

   &.light {
      background-color: lighten($bg_status_winter,15%) !important;
   }
   input { background-color: $bg_status_winter_input; }
}

.geschlossen {
   background-color: $bg_status_closed !important;
   color: $white;
   a,
   a:link,
   a:visited {
      color: $white;
   }
   &.light {
      color: $black;
      background-color: lighten($bg_status_closed,35%) !important;
      a,
      a:link,
      a:visited {
         color: $black;
      }
   }
}


.orange { 
   background-color: #ffb515; 
   input {
      background-color: #ffca7e; 
   }
}
.grey { 
   background-color: #cccccc;
   input {
      background-color: #eeeeee; 
   }
}


noshow0,
tr.noshow0,
tr.NoShow_0 {
   background-color: $black;
   color: $white;
}

noshow1,
tr.noshow1,
tr.NoShow_1 {
   background-color: #FFFF00;
   color: $black;
}

tr.noshow2,
tr.NoShow_2 {
   background-color: #FFD320;
   color: $black;
}

noshow3,
tr.noshow3,
tr.NoShow_3 {
   background-color: #FF6309;
   color: $white;
}

noshow4,
tr.noshow4,
tr.NoShow_4 {
   background-color: #ff0000;
   color: $white;
}

noshowres_ok,
tr.noshowres_ok,
tr.NoShow_Reservation_OK {
   background-color: $white;
   color: $black;
}

noshowres_nok,
tr.noshowres_nok,
tr.NoShow_Reservation_NOK {
   background-color: #ff0000;
   color: $white;
}


important,
tr.important {
   background-color: $red !important;
   background-color: $bg_status_important !important;
   color: $white;
}






tr.storno_row {
   background-color: lighten($red,45%);
}

tr.noshow_row {
   background-color: lighten($red,48%);
}


tr.valuta_row {
   background-color: lighten($green,45%);
}

.day_sat {
   background-color: $light-grey;
}

.day_sun {
   background-color: $medium-grey;
}

.in_daterange {
   background-color: $ar_green;
   color: $white;
}

.is_today {
   font-weight: bold;
}
