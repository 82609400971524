$payment_types_subdir: 'payment_tools/';

@each $current-payway in $payways-list {
    $i: index($payways-list, $current-payway);
    $myurl: $assetStaticPath + $payment_types_subdir + $current-payway + '.svg';
    .pay-#{$current-payway} {
        background-image: url($myurl);
        background-size: nth($payways-width-list, $i) auto;
    }
}


.payways {
   width: 100% auto;
   margin-top: $medium-pad;
   margin-bottom: $medium-pad;

   .payway {
       content: "";
       display: inline-block;
       margin-right: 5px;
       vertical-align: text-top;
       background-color: transparent;

// test that
//       background-size: contain;
//       padding: 20px;
       background-repeat: no-repeat;
       background-position: center center; 

       width: 250px;
       min-height: 50px;
       height: 100px;  
       
       margin: rem(11);
   }

}
