// tables
table {
   width: 100%;

   &.account,
   &.bookings,
   &.guests,
   &.resreqs,
   &.resreq,
   &.guests_arrived,
   &.bookings_state,
   &.members_grid,
   &.guests_grid,
   &.rooms_editable,
   &.specials,
   &.payouts {
     font-size: 75% !important;
     td,
     th {
       padding-left: $mini-pad;
       padding-right: $mini-pad;
     }
   }
   &.account {
     th {
       text-align: left !important;
     }
   }
   &.vpop {
     th {
       text-align: center !important;
     }
   }
   &.book_members {
     width: 100% !important;
     @include media-breakpoint-down(md) {
       overflow-x: auto;
       display: block;
       width: 100%;
     }
   }
   &.huettendetails {
      tr:first-child > td,
      tr:first-child > th {
         border-top: none !important;
      }
   }

   tr.erteilt { 
      background-color: $ar_erteilt_bg !important; 
      &:nth-of-type(odd) {
         background-color: darken($ar_erteilt_bg,5%) !important;
      }
   }

   tr.storniert {
      background-color: $ar_storniert !important;
      &:nth-of-type(odd) {
         background-color: darken($ar_storniert,5%) !important;
      }
   }

   tr.erfuellt {
      background-color: $ar_erfuellt !important;
      &:nth-of-type(odd) {
         background-color: darken($ar_erfuellt,5%) !important;
      }
   }

   tr.group_leader {
      background-color: $ar_group_leader !important;
      &:nth-of-type(odd) {
         background-color: darken($ar_group_leader,5%) !important;
      }
   }

   &.costs {

      tr {
         &.red,
         &.status_true { color: $red; }
      }
      td.right,
      th.right {
         text-align: right;
      }
   }
   &.moneytable {
      th:not(:first-child), 
      td:not(:first-child) {
         text-align: right;
      }

   }

   &.specials {
      tr.nobook {
         background-color: $dark-grey;
         color: $white;
      }
      tr.nobook_too {
         background-color: $light-grey;
         input {
            background-color: $light-grey;
         }
      }

      tr.closed {
         background-color: $grey;
         color: $white;
      }
      tr.closed_too {
         background-color: $medium-grey;
         input {
            background-color: $medium-grey;
         }
      }

   }

   tr.sub {
      color: $grey;
   }
   td.sub {
         padding-left: $large_pad;
         font-style: italic;
   }


   &.resreq {
      td { width: 50%; }
   }
}





// break on smallest screens
@include media-breakpoint-down(xs) {
   .calc {
     tr {
       display: table;  width:100%;
     }
     td {    
       display: table-row;
       text-align: center !important;  
//       padding-right: $small-pad !important;
       img {
          max-width: 100%;;
          margin: 0 auto;
       }
     }
     th {
       display: table-row;
     }
     tr {
       margin-bottom: $large-pad;
     }
   }
   .bargraph {
     width: 100% !important;
     td {
       text-align: right !important;
     }
   table.bookings_state td { text-align: right !important; }
   }
}

@media (max-width:424px) {
   .calc-xxs {
     tr {
       display: table;
       width:100%;
     }
     td {
       display: table-row;
       text-align: center !important;
//       padding-right: $small-pad !important;
       img {
          max-width: 100%;
          margin: 0 auto;
       }
     }
     th {
       display: table-row;
     }
     tr {
       margin-bottom: $large-pad;
     }
  }
}



