// Nav

// responsive for slide nav


.main-menu {
   @include media-breakpoint-up(lg) {
      display: none;
   }
   @include media-breakpoint-down(md) {
      width: 300px;
   }
   @include media-breakpoint-down(sm) {
      width: 100%
   }
}



div.button-list,
div.bt_big {
   width: 100%;
   background-color: $light-grey;
   margin-top: $medium-pad;
   margin-bottom: $medium-pad;
   padding: $mini-pad;
   @include rounded();
   & > * {
      margin-left: $small-pad;
      @extend .d-inline;
   }
}

input.btn { }
input.btn_reset { }

.button-help {
// form-inline d-inline d-flex justify-content-end

   position: absolute;
   top: 0;
   right: 0;

   i {
      font-size: rem(18);
   }
   & > * {
      margin-left: $small-pad;
      @extend .align-self-center;
   }

   .header_current_book {
      background-color: $white;
      font-size: rem(12);
      line-height: rem(14);
      padding-left: $mini-pad;
      padding-right: $mini-pad;
      @include rounded();
      @include media-breakpoint-down(sm) {
         display: none;
      }
   }
}


.navbar {
   @include rounded-top();
   @extend .shadow_mini;
   button.navbar-toggler {
      background-color: transparent !important;
   }
   .navbar-toggler-icon {
   }
}


nav {
   background-color: $white;
   a,
   a:link {
      color: $black !important;
   }
   .glyphicon {
//      @media (max-width: $screen-sm-max) {
      @include media-breakpoint-down(sm) {
         font-size: rem(24);
      }
   }

   button.btn {
//      margin-left: $small-pad;
   }
   button.navbar-toggler {
      color: $white;
      background-color: $ar_green_light;
      .icon-bar {
         color: $white;
      }
   }
   button.btn-link {
       margin-right: $small-pad;
       border: 0 !important;
       color: $green;
   }
   .navbar-form {
//      padding-right: rem(4);
//      padding-left: rem(4);
   }
   ul {
      li {
         &.nav-item.active { 
            a { color: $ar_green !important; }
         }
      }
   }
   div.dropdown-menu {
      a.active {
         background-color: $ar_green_light !important;
      }
      a:focus,
      a:target {
         background-color: $ar_green !important;
         color: $white !important;
      }

   }
   .navbar-brand {
      display: none;
      @include media-breakpoint-down(md) {
         display: inline;
      }
   }
}



// login app
.loginapp {
   span {
      .txt {
         font-size: 20px;
//         @media (max-width: $screen-sm-max) {
         @include media-breakpoint-down(sm) {
           display: none;
         }
      }
   }
}



// Micronavigation

.breadcrumb { 
   margin: 0;
   padding: $small-pad $small-pad !important;
   background-color: transparent !important;
   color: $ar_blue_dark;

//   @extend .shadow_text;
   font-size: rem(12);


   a,
   &:link {
     color: $ar_blue_dark;
   }
}


          
// footer

.footer_nav,
.social_nav,
.teaser_row {
      font-size: rem(14);

   h3 {
      border-bottom: 1px solid $medium_grey;	
      color: $white;
      font-size: rem(18);
      font-weight: 600;
      line-height: 27px;
      text-transform: uppercase;
      padding-top: 0;
      margin-top: 0;
//      padding: 20px 0 10px;
   }

   ul {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
      margin-top: 15px;
      color: $white;

      li {
         a {
            padding: 0 0 5px 0;
            display: block;
         }
         &.current {
            font-weight: bold;
         }
      }

   }
}




// responsive tab nav

// Justified nav links
// -------------------------

div.nav_sub_huette_main {
   @include media-breakpoint-down(md) {
      ul.nav {
         display: block !important;

         li {
            background-color: $white;
            margin-bottom: $mini-pad;
            @include rounded();
//            border-bottom: 1px solid $grey;
         }
      }
   }   
}

div.TOC_Index { 
   ul {
      list-style-type: none;
      li {
         &:before {
            /*Using a Bootstrap glyphicon as the bullet point*/
            content: "\e080";
            font-family: 'Glyphicons Halflings';
            font-size: 80%;
            float: left;
            margin-left: -$medium-pad;;
            color: $medium-grey;
         }
      }
   }
}



ul.nav_content {
      list-style-type: none;

      li {
         &:before {
            /*Using a Bootstrap glyphicon as the bullet point*/
            content: "\e080";
            font-family: 'Glyphicons Halflings';
            font-size: 80%;
            float: left;
            margin-left: -$medium-pad;;
            color: $medium-grey;

         }
      }
}


// rate radios


.rateform {

   .field {
      margin-bottom: $large-pad;
   }

   .labelbox,
   .labelbox_hor {
      font-size: rem(18);
   }
}


.stars_panel {

   width: 100%;
   background-color: $light-grey;

   .rate-radios {
      input[type=radio]:checked + label>img {
         box-shadow: 0 0 4px 4px #090;
      }
      label {
         @include rounded();
         background-color: $medium-grey;

         &>img {
         }
         &:hover {
            background-color: darken($medium-grey,15%);
         }

         &.active {
              background-color: $ar_active_button_green !important;
         }
         &.focus {
            border: 0 !important;
            @include opacity(0.8);
         }
      }
   }
   @include media-breakpoint-down(sm) {
      .btn {
         padding: 0.5em 0.75em;  
      }
   }
}

select.date_roller {
   option {
     &.bg_sunday { background-color: $medium-grey !important; }

     span {
       .wd {}
       .day {}
       .year {}
       .places {
         norm { }
         closed { background-color: $bg_free_closed; color: $white; }
         free_lower_20 { background-color: $bg_free_lower_20; }
         free_lower_0 { background-color: $bg_free_lower_0; }
         free { background-color: $bg_free; }
       }
     }
   }
}


// booking buttons

.opts_panel {
   width: 100%;
   .checkboxes {

      img { width: 30px; height: 30px; }

      label {
         margin-right:0.1em;
         margin-bottom: 0.5em;
         @include rounded();
         background-color: $medium-grey;

         &>img {
         }
         &:hover {
            background-color: darken($medium-grey,15%);
         }
         &.active {
           background-color: $ar_active_button_green !important;
           img {
              @include opacity(0.6);
           }
        }
         &.focus {
            border: 0 !important;
            @include opacity(0.8);
         }
      }

   }
   @include media-breakpoint-down(sm) {
      .btn {
         padding: 0.5em 0.75em;
      }
   }
}


.inkasso,
.onoff {
   .text-radios {
      input[type=radio]:checked + label {
         box-shadow: 0 0 4px 4px #090;
      }

      label {
         @include rounded();
         background-color: $medium-grey;

         &>img {
         }
         &:hover {
            background-color: darken($medium-grey,15%);
         }

         &.active {
            background-color: $ar_active_button_green !important;
            color: $white;
         }
         &.focus {
            border: 0 !important;
            @include opacity(0.8);
         }
      }
   }
   @include media-breakpoint-down(sm) {
      .btn {
         padding: 0.5em 0.75em;
      }
   }
}


.tab_menu {
   margin-bottom: $medium-pad;
}


#cal_heatmap1,
#cal_heatmap2 {
   width: 40%;
}
