.alert .alert-icon-col {
    min-width: 25px;
    max-width: 25px;
}

// re-enable in >bs4.0
// formats
//.alert {
//   @each $color, $value in $theme-colors {
//      &.alert-#{$color} {
//         a {
//            color: darken($value, 10%);
//            font-weight: $alert-link-font-weight;
//         }
//      }
//   }
//}


.arprogress {
   .progress-bar {
      height: rem(24);
      font-size: rem(18);
      line-height: rem(24);
      font-weight: bold;
   }
}
