/* new panel style */

div.container.panel_list {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
}

.list_panel {
   .title {
      font-weight: bold;
   }
   .org_short { font-weight: bold; }

   .main { overflow: hidden; }

   .unknown { font-weight: normal; font-style: italic; }
   .ok { }

   .name_sec { color: #9999bb; }
   .org { font-style: italic; color: #004400; }
   .light { color: $grey; }
    & > div:not(.panel_btns) { padding: rem(8); }
//   .div.btns { margin-top: rem(2); }
}

// .panel_thumb { width: 150px; height: 150px; }

/* huette ov */

.huette_panel_ov {

   .title {
      font-weight: bold;
      font-size: 120%;
   }

   .col-fixed { width: 150px; }

   .ct_top { height: 116px; }
   .ct_bottom { height: 20px; }

   .panel_image {
      margin-left: $medium-pad;
      img {
         width: 150px;
         height: 150px;
      }
   }

   @include media-breakpoint-down(sm) {
     .panel_right {
        margin-top: $medium-pad;
     }
   }

   .main { overflow: hidden; }
   .name_sec { color: #9999bb; }
   .panel_btns {
      .arid { text-align: right; }
      .hut_status { margin-bottom: 0; text-align: right; }
      .btns {
         text-align: right;
         button,
         a {
            @extend .btn-sm;
            margin-top: $small-pad;
            width: 100%;

         }
      }
   }
}

/* ratings */

.rating {
   margin-top: rem(16);
   margin-bottom: rem(36);
   div.sum_summer { color: $rt_summer; }
   div.sum_winter { color: $rt_winter; }

   .nick {
         @extend .shadow_mini;
         width: 33%;
         color: $white;
         font-size: rem(16);
         margin-bottom: -3px;
         margin-left: $medium-pad;
         padding: $mini-pad;
         padding-left: $small-pad;
         background-color: $green;
         @include rounded();
         border-bottom-left-radius: 0;
 	 border-bottom-right-radius: 0;
         @extend .shadow_mini;

         font-style: italic;
   }

   .text_box {
      .title { font-size: 1.5em; color: $black; }
      padding-right: $medium-pad;
   }
   .box_ov {
      @extend .shadow_mini;
      .note_sum { font-size: rem(24); }
      background-color: $light_grey;
      .star-col { padding: $medium-pad; }
   }

   .box {
       @extend .shadow_mini;
       padding: 1em;
       border: 1px #999999 solid;
       border-radius: 5px;
       background-color: #FFF8DE;
    }
   .box_winter {
       @extend .shadow_mini;
       padding: 1em;
       border: 1px #999999 solid;
       border-radius: 5px;
       background-color: #DCF4FF;
    }

   .count { color: #777777; margin-bottom: 1em; }

   .star_box { text-align: right; }
   .starrec,
   .starrecsum { font-size: rem(13); height: 20px; line-height: 20px; padding-right: 25px; text-align: right; }
   .starsum {
      margin-top: $small-pad;
      font-weight: bold;
   }
   .stars {
   }
   div.comments {
      margin-top: $small-pad;
      width: 75%;
      .admin { padding: 4px; background-color: #ffeeee; }
      .hut { padding: 4px; background-color: #eeffee;  }

   }
   .starrec { height: 20px; line-height: 20px; padding-right: 25px; }
    .visit_time { color: #444444; }
    .hut_id { color: #444444; }
    .rating_time { font-size: 0.8em; color: #444444; }
    .rating_status {
       color: #444444;
       span {
         font-weight: bold;
       }
    }
    .greytxt { color: #888888; font-size: 0.75em;  }

h4 span.ar_id { color: #444444; }
span.status_neu { color: orange; }
span.status_neu_bearbeitet { color: orange; }
span.status_validiert { color: green; }
span.status_entfernt { color: grey; }
span.status_abgewiesen-hut { color: red; }
span.status_abgewiesen-admin { color: red; }
span.status_entfernt { color: grey; }
span.status_disput { color: red; }

   div.comments {
      width: 75%; 
      .admin { padding: 4px; background-color: #ffeeee; }
      .hut { padding: 4px; background-color: #eeffee;  }
   }
}
