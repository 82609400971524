/* site overall stuff */

* {
    box-sizing: border-box;
}


::selection {
  background: $light-grey;
}
::-moz-selection {
  background: $light-grey;
}


body {
    overflow-x: hidden;

//    padding-top: 20px;
    padding-bottom: 20px;

//    background: url('/common/kalenderbilder/5.jpg') no-repeat center top fixed;
//    -webkit-background-size: cover;
//    -moz-background-size: cover;
//    -o-background-size: cover;
//    background-size: 100% auto;
   background-color: $bg_grey;

// background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);

// background: linear-gradient(-90deg, transparent, $bg_grey);

//    background: linear-gradient(#f2f2f2, white);
//    background: #f2f2f2;

//   /* For browsers that do not support gradients */
//    background: -webkit-linear-gradient(#f2f2f2, white);

//    /* For Safari 5.1 to 6.0 */
//    background: -o-linear-gradient(#f2f2f2, white);

//    /* For Opera 11.1 to 12.0 */
//    background: -moz-linear-gradient(#f2f2f2, white);

//    /* For Firefox 3.6 to 15 */
//    background: linear-gradient(#f2f2f2, white);

    /* Standard syntax */
}




img {
    margin-bottom: 0 !important;
}

.popover {
  max-width: none;
}

// content area
main {
   margin-top: $large-pad;
   .glyphicon { opacity: 0.5; }

   h2,h3,h4 { margin-top: $large-pad; }


   > .container {
     padding-left: 0 !important;
     padding-right: 0 !important;

     > .row {

       > div.singlepage { 
          background-color: white;
          @include rounded();
          @extend .shadow_mini;
       }
       > .col {
          ul.hprs_nav {
             margin-bottom: 0 !important;
             padding-bottom: 0 !important;
          }
         > * {
            width: 100% !important;
         }

          *:not(.singlepage) &
          > p,
          > ul:not(.hprs_nav),
          > table,
          > div:not(.page_btns,#pub_button_list,.pub_button_list,.nav_sub_huette_main,.hprs_content,.alert,.paginator,.rating) {
              border: 0 !important;
              box-sizing: border-box;
              background-color: $white;
              margin-bottom: $medium-pad;
              @include rounded();
              @extend .shadow_mini;

              * { box-sizing: border-box; }
         }

         > *:not(.huette_panel_ov,.list_panel,.clearfix,.rating,.head2) {
            // padding-top: rem(16);
            // padding-bottom: rem(16);
            padding: $medium-pad;
         }

          div.hprs_content {
             margin-top: 0 !important;
             box-sizing: border-box;
             background-color: $white;
             margin-bottom: $medium-pad;

             @include rounded-bottom();
             @extend .shadow_mini;

             // .head2 {
              //  margin-bottom: rem(18);
             // }
             .content_pre { margin-bottom: $medium-pad; }
             .content_post { margin-top: $medium-pad; }
          }

         > ul {
            padding-left: rem(64) !important;
         }

         > table { 
            padding: $medium-pad !important; 

         }

         .clearfix,
         .page_btns,
         #pub_button_list,
         pub_button_list {
            background-color: transparent;
         }
         .huette_panel_ov {
            box-sizing: border-box;
         }
         .clearfix { margin-bottom: 0; margin: 0;}
         img {
         }
         .head2,
         .pub_head { margin-top: 0; padding: 0; }
         .head_sub {
           h2 {
             color: $green_grey !important;
           }
         }
         .page_btns {
            button,
            a {
               margin-bottom: $small-pad;
            }
         }
       }
     }
   }
   .image_logo {
     img {
       @extend .img-fluid;
     }
   }
   .content_post,
   .content_pre {
     @include media-breakpoint-down(xs) {
       img {
         margin: 0 auto;
         width: 100%;
       }
     }
   }
}


// help
.help_content {
   h1 {
      font-size: rem(18);
   }
   h2 {
      font-size: rem(17);
   }
   h3 {
      font-size: rem(16);
   }
   h4 {
      font-size: rem(15);
   }
   p,
   div,
   ul,
   table {
      font-size: rem(12);
   }
}

.paginator {
   background-color: $ar_green_light !important;
}
