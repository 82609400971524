#bg {
      z-index: -100;
      position: absolute;
      width: 100%;
      height: 275px;
//      background: url('#{$assetPath}/sbg/08_2048x650.jpg') no-repeat center 0 fixed;
      background-size: 100% auto;

      @include gradient_img( rgba(235,236,237,0), rgba(235,236,237,1), '#{$assetPath}/sbg/08_2048x650.jpg' )


      @include media-breakpoint-down(sm) {
         background-position: center 0;
      }

      @include media-breakpoint-down(xs) {
         background: none;
      }

      @include media-breakpoint-up(lg) {
         background-position: center 0;
      }

      @media (min-width: 2048px) {
         height: 300px;
      }
}

header {
    @include media-breakpoint-up(sm) {
       padding-top: rem(7);
    }
   .container {
      background-color: $grey; /* Fall-back for browsers that don't support rgba */
      background-color: rgba(255,255,255,.5);
      @include rounded();

      @include media-breakpoint-down(xs) {
         background-color: $white;
      }
   }
   .logo_con {
      display: block;
      margin: $medium-pad;

      .logo {
      }

      img.logo_img,
      svg.logo_svg {

	margin: 0 auto;
	width: 100%;

      }


      z-index: 1;
//     @include media-breakpoint-down(xs) {
//        width: 100%;
//       }
   }


   .head-btns-right {
//      width: 500px;
        padding: $medium-pad;

        button {
           font-size: rem(20);
        }

        i.mdi {
//           font-size: rem(18);
        }

        @include media-breakpoint-down(lg) {
            font-size: rem(18);
        }
        @include media-breakpoint-down(md) {
           margin: 0 auto !important;
           float: none !important;
        }
   }
   .main_navigation {
      nav {
//      background-color: $white;
      min-height: 60px;
         @include rounded();
         @extend .shadow_mini;
         #navbar {
            border-color: transparent;
         }
      }
   }
   #nav_main {
      width: 100%;
   }
   .navbar {
      width: 100%;
      border-color: transparent;
//      margin-bottom: 10px;
  
   }
   .crumbs {
//      margin-top: 0;
//      padding-top: 0;
   }
}
