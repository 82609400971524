body {
   font-family: 'Roboto', sans-serif;
}

html {
   font-size: 14px;
   @include media-breakpoint-up(sm) {
      font-size: 16px;
   }
}

      
h1,h2,h3,h4,h5 {
   color: $blue_grey;
}

h1 {
//   @media (max-width: $screen-sm-max) 
   @include media-breakpoint-down(sm) {
      font-size: 24px;
   }
}

.header_current_book {
   font-family: 'Roboto-Regular', sans-serif;
}

main {
   h3 {
     color: rgba($blue_grey, 0.85);
   }
   h4 {
    color: rgba($blue_grey, 0.65);
   }
   h5 {
    color: rgba($blue_grey, 0.5);
   }
}


// Social Media - Font Awesone Styles

.fa {
   padding: 20px;
   font-size: 30px;
   width: 50px;
   text-align: center;
   text-decoration: none;
   margin: 5px 2px;

   :hover {
      opacity: 0.7;
   }

   fa-facebook {
      background: #3B5998;
      color: white;
   }

   .fa-twitter {
      background: #55ACEE;
      color: white;
   }

   .fa-google {
      background: #dd4b39;
      color: white;
   }
}

.red {
   color: $red;
}
