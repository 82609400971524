//input.btn_small,
input.btn_small_loose {
    background-color: #00a000;
    border: medium none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    font-size: 13px;
    margin-top: 10px;
    padding: 2px;
    margin: 2px;
}

//input.btn_small_loose {
//    background-color: #a0a000;
//}

input.btn_vsmall {
    background-color: #00a000;
    border: medium none;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    font-size: 8px;
    margin-top: 9px;
    padding: 2px;
    margin: 2px;
}
select.btn_vsmall {
    font-size: 9px;
    border-width: 1px;
    border-radius: 2px;
    background-color: white;
}

input.upload {
    background-color: #686868;
    border: medium none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    font-size: 13px;
    margin-top: 10px;
    padding: 2px;
    margin: 2px;
}

.user_input input.isNew,
.user_input input.isNewOpt,
.user_input input.isNok,
.user_input input.isok {
}

input.birthdate {
//    width: 6em !important;
}


.user_input input.isNew {
    border: 1px solid grey;
    background-color: #ffff77;
}

.user_input input.isNewOpt {
    border: 1px solid grey;
    background-color: #cccccc;
}

.user_input input.isNok {
    border: 1px solid red;
    background-color: #FFB1B1;
}
.user_input input.isok {
    border: 1px solid green;
    background-color: #A5FB98;
}

// div.bt_big {  width: 100%; margin:0; margin-top: 16px; margin-bottom: 16px; padding: 4px; background-color: #dddddd; border-radius: 6px;  }


//.ZMSForm div.clearfix { 
//   clear: both; 
//   height: 6px;
//}

/* ### form submit button ### */
//.ZMSForm input.btn {}
//.ZMSForm input.btn_reset { }


.borders { border: 1px #000000 solid; }

// table.places { width:80%; font-size: 10px; }
// table.places td { font-size: 10px; }
// table.places td input { font-size: 10px; }

// table.grid { border-collapse: collapse; }
// table.grid th { border-collapse: collapse; }
// table.grid td { border: 1px solid #000000; }

//table.table_preise { border-width: 0; padding: 5px;  border-spacing: 0; border-collapse: collapse; }
//table.table_preise th { padding: 3px; }
//table.table_preise td { padding: 3px; }

//.orange { background-color: #ffb515; }
//.orange input { background-color: #ffca7e; }
//.grey { background-color: #cccccc; }
//.grey input { background-color: #eeeeee; }
//.winter { background-color: #83caff; }
//.winter input { background-color: #ccefff; }

table.rowselect { border: 0; border-spacing: 0; }
table.rowselect tr { height: 25px; min-width: 25px; border: 0; }
table.rowselect td { min-height: 25px; min-width: 25px; border: 0; }


// table.account { width: 100%; border-spacing: 0; border-collapse: collapse; }
// table.account th { background-color: #bbbbff; }
// table.account tr { }
// table.account td { border: 1px solid #999999; }
// table tr.account_sub {  border: 1px solid #c04040; }


// table.huettenliste { width: 95%;  border-collapse: collapse; }
// table.huettenliste th { border: 1px solid #a0a0a0; padding: 3px; background-color: #bbbbff; color: #0000ff; }
// table.huettenliste td { border: 1px solid #a0a0a0; padding: 3px; }

// table.liste { width: 95%;  border-collapse: collapse; }
// table.liste th { border: 1px solid #a0a0a0; padding: 3px; background-color: #bbbbff; color: #0000ff; }
// table.liste td { border: 1px solid #a0a0a0; padding: 3px; }

// table.huettendetails { width: 95%;  border-collapse: collapse; }
// table.huettendetails th { border: 1px solid #a0a0a0; padding: 3px; background-color: #bbbbff; color: #0000ff; }
// table.huettendetails td { border: 1px solid #a0a0a0; padding: 3px; }

table.book { width: 95%; border-spacing: 0; border-collapse: collapse; }
table.book th { border: 1px solid #ffffff; padding: 6px; background-color: #bbbbff; }
table.book td { text-align: center;  border: 1px solid #cccccc; }
table.book td.blind,
table.book th.blind { border: 0; }

//table.table { width: 100%; border-spacing: 0; border-collapse: collapse; margin-top: 6px; margin-bottom: 6px; }
//table.table th { background-color: #bbbbff; }
//table.table tr { }
//table.table td { border: 1px solid #999999; padding: 2px; }
//table tr.odd {  border: 1px solid #c04040; }

table.transparent { width: 100%; border-spacing: 0; border-collapse: collapse; margin-top: 6px; margin-bottom: 6px; }
table.transparent td { vertical-align:top; text-align: left; border: 0px; }

table.huetteninfo { width: 100%; border-spacing: 0; border-collapse: collapse; margin-top: 8px; margin-bottom: 8px; }
table.huetteninfo td { padding: 3px; }

table.bargraph { border: 0; border-spacing: 0; border-collapse: collapse; margin-top: 1px; margin-bottom: 1px; font-family: Arial, Helvetica, sans-serif; }
table.bargraph td { padding: 0; }

// input.bt_all { font-size: 6px; line-height: 6px; padding: 1px; }

table td.fulltrans { border-width: 0; }

table.payment_type { width: 100%; border-spacing: 0; border-collapse: collapse; margin-top: 6px; margin-bottom: 6px; }
table.payment_type th { vertical-align: center; text-align: center; padding: 2px; }
table.payment_type td { vertical-align: center; text-align: right; padding: 2px; border-right: 1px solid #c0c0c0; }

table.agb { width: 100%; font-size: 80%;  line-height: 140%; border: 1px solid black; border-collapse: collapse; border-spacing: 0; }
table.agb th { padding: 6px; text-align: left; vertical-align: center; background-color: #cccccc; border: 1px solid black; }
table.agb td { padding: 6px; text-align: left; vertical-align: top; border: 1px solid black; }


//.rateform {
//   width: 100%;
//}
//.rateform fieldset { margin-bottom: 16px; border-radius: 5px; padding: 1em; padding-bottom: 0; }
//.rateform .field { width: 100%; margin-bottom: 1em; }
//.rateform .labelbox { margin-bottom: 0.2em; font-weight: bold; }
//.rateform .labelbox_hor { margin-bottom: 0.2em; margin-top: 1em; font-weight: bold; width: 300px; float:left; display: inline-block;}

// .rating_radio { }

// label.radio_hor img { width: 20px; height: 20px; }
// label.radio_hor { display: block; }

//label.radio_vert input { vertical-align: middle; horozontal-align: middle; width: 20px; height: 20px; }
//label.radio_vert img { width: 20px; height: 20px; padding:0; margin:0; padding-left: 5px; }
//label.radio_vert { width: 20px; height: 20px; display: inline-block;  margin-right: 4px; }
//abel.radio_vert input { }

//.bt_my_edit { width: 100%; text-align: left; }
//.bt_my_edit form { display: inline-block; }

// .search_form { witdh: 100%; padding: 4px; border-radius: 5px; background-color: #fff5cf;}


// div.btns_logform { float: right; }
// span.btn_login { display: inline-block; float: left; }
// span.btn_register { display: inline-block; float:left; }
// span.btn_pwforgot { display: inline-block; float:left; }
// span.btn_pwforgot { }

//span.btn_login input,
//span.btn_register input,
//div.btn_logback input{ width: 90px; }

//span.btn_login input { margin-right: 0; }

// div.btn_logback { width: 100px; float: left; }


// input#login_email { width: 125px; }
// input#login_passwd  { width: 125px; }

//.page_btns input { width: 115px; }

//div.user_input { width: 95%; } 
div.user_input div.error { width: 100%; text-align: right; }


div.form_block { width: 100%; overflow: hidden;  margin-bottom: 0.5em; margin-top: 0.5em; }
div.form_block section { float:right; }
div.form_block section div { float: left; margin-right: 0.3em; }
div.form_block section div label { display: block; }
div.form_block section div.nolabel { padding-top: 2em; }
div.form_block section div.full { clear: both; }
div.form_block section div.last { clear: both; }
div.form_block em { }
